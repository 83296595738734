var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "img-in-row" }, [
    _c("div", {
      staticClass: "admin-table__pic",
      style: "background-image: url(" + _vm.data.url + ")",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }